import { siteConfig } from '../config/site.config'

export default function ContractInfo() {
    const contract = siteConfig.contract
    const wikiUrl = siteConfig.content.wiki.url

    return (
        <section id="about" className="py-16 bg-ugandan-black text-ugandan-white">
            <div className="container mx-auto px-4">
                <div className="max-w-3xl mx-auto text-center font-arcade">
                    <h2 className="text-3xl font-bold mb-6">Contract Information</h2>
                    <div className="bg-ugandan-red bg-opacity-20 p-6 rounded-lg">
                        <p className="text-sm mb-2">Contract Address:</p>
                        <code className="bg-ugandan-black px-4 py-2 rounded text-sm break-all font-retro">
                            {contract.address}
                        </code>
                        <p className="mt-6 text-gray-300">
                            {contract.description.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))}
                        </p>
                    </div>

                    <div className="mt-8">
                        <a
                            href={wikiUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block bg-ugandan-red hover:bg-opacity-90 text-ugandan-white px-6 py-3 rounded-lg transition-all duration-300 text-sm"
                        >
                            {siteConfig.content.wiki.buttonText}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}