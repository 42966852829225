import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function MemeCarousel() {
    const [memes, setMemes] = useState([])

    useEffect(() => {
        const loadMemes = async () => {
            try {
                const memeContext = import.meta.glob('/public/memes/*.{jpg,jpeg,png,gif,webp,avif}')
                const memeFiles = Object.keys(memeContext).map(path =>
                    path.replace('/public', '')
                )
                setMemes(memeFiles)
            } catch (error) {
                console.error('Error loading memes:', error)
            }
        }

        loadMemes()
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        adaptiveHeight: false,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    }

    if (memes.length === 0) {
        return null
    }

    return (
        <section id="memes" className="py-16 bg-ugandan-black">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold mb-8 text-center text-ugandan-white">
                    Meme Gallery
                </h2>
                <div className="max-w-3xl mx-auto relative">
                    <div className="carousel-container">
                        <Slider {...settings}>
                            {memes.map((meme, index) => (
                                <div key={index} className="px-2">
                                    <div className="relative pt-[56.25%]">
                                        <img
                                            src={meme}
                                            alt={`Meme ${index + 1}`}
                                            className="absolute inset-0 w-full h-full object-contain bg-ugandan-black rounded-lg"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}