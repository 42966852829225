import TiktokIcon from '../assets/icons/tiktok.svg'
import JupiterIcon from '../assets/icons/jupiter.svg'
import BullxIcon from '../assets/icons/bullx.svg'
import PhotonIcon from '../assets/icons/photon.svg'
import DextoolsIcon from '../assets/icons/dextools.svg'

const icons = {
    tiktok: TiktokIcon,
    jupiter: JupiterIcon,
    bullx: BullxIcon,
    photon: PhotonIcon,
    dextools: DextoolsIcon
}

export default function Icon({ name, className }) {
    if (!icons[name]) return null

    return (
        <img
            src={icons[name]}
            alt={`${name} icon`}
            className={`${className} w-8 h-8 `}
        />
    )
}