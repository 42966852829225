import { useState, useEffect } from 'react'
import { siteConfig } from '../config/site.config'
import { IoClose } from 'react-icons/io5'
import ReactPlayer from 'react-player'
import { FaPlay } from 'react-icons/fa'

export default function IntroVideo() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { title, subtitle, buttonText } = siteConfig.content.hero

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [isModalOpen])

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <section id="home" className="relative h-[90vh] bg-ugandan-black">
                <video
                    className="w-full h-full object-cover opacity-90"
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster={siteConfig.assets.introVideo.poster}
                >
                    <source src={siteConfig.assets.introVideo.src} type={siteConfig.assets.introVideo.type} />
                </video>

                <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-b from-transparent to-ugandan-black">
                    <div className="text-center text-ugandan-white px-4 sm:px-6">
                        <h1 className="text-4xl sm:text-2xl md:text-6xl font-bold mb-4 mt-8 sm:mt-0 ">{title}</h1>
                        <p className="text-md sm:text-xs md:text-md mb-8 flex justify-center">{subtitle}</p>
                        <div className="flex justify-center">
                            <button
                                onClick={openModal}
                                className="bg-ugandan-red px-8 py-3 rounded-lg text-md hover:bg-opacity-90 transition flex items-center gap-4"
                            >
                                <FaPlay className="text-sm" />
                                {buttonText}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black/95 z-[60] flex items-center justify-center p-4">
                    <div className="relative w-full max-w-6xl">
                        <button
                            onClick={closeModal}
                            className="absolute -top-12 right-0 text-white hover:text-ugandan-red transition z-[70]"
                        >
                            <IoClose size={32} />
                        </button>
                        <div className="relative pt-[56.25%]">
                            <ReactPlayer
                                url={siteConfig.assets.introVideo.src}
                                width="100%"
                                height="100%"
                                playing
                                controls
                                className="absolute top-0 left-0"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}