import Header from './Header'
import Footer from './Footer'
import IntroVideo from './IntroVideo'
import ContractAddress from './ContractAddress'
import MemeCarousel from './MemeCarousel'
import UsefulLinks from './UsefulLinks'

export default function Layout() {
    return (
        <div className="min-h-screen flex flex-col bg-ugandan-black overflow-x-hidden pt-20">
            <Header />
            <main className="flex-grow">
                <IntroVideo />
                <ContractAddress />
                <MemeCarousel />
                <UsefulLinks />
            </main>
            <Footer />
        </div>
    )
}