import { siteConfig } from '../config/site.config'

export default function Footer() {
    return (
        <footer className="bg-ugandan-black text-ugandan-white py-12 border-t border-ugandan-red border-opacity-20">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div>
                        <h3 className="text-xl font-bold mb-4 text-ugandan-red">Quick Links</h3>
                        <ul className="space-y-2 text-gray-400">
                            {siteConfig.navigation.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className="hover:text-ugandan-white transition">
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-xl font-bold mb-4 text-ugandan-red">Trading</h3>
                        <ul className="space-y-2 text-gray-400">
                            {Object.entries(siteConfig.trading).map(([key, value]) => (
                                <li key={key}>
                                    <a href={value} className="hover:text-ugandan-white transition" target="_blank" rel="noopener noreferrer">
                                        {key}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-ugandan-red border-opacity-20 text-center text-gray-400">
                    <p>{siteConfig.content.footer.copyright}</p>
                </div>
            </div>
        </footer>
    )
}