import Layout from './components/Layout'
import './App.css'

function App() {
  return (
    <div className="min-h-screen bg-ugandan-black font-arcade text-xs">
      <Layout />
    </div>
  )
}

export default App